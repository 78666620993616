import React, { useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  selectedMediumSelector,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import AccessOperations from "./components/AccessOperations";
import { Box, Stack, Typography, Container } from "@mui/material";
import { toast } from "react-toastify";
import { PencilSquare } from "react-bootstrap-icons";
import { THEME } from "app/config/constants";
import PrimaryRoleDialog from "app/components/common/Dialogs/schoolManagement/PrimaryRoleDialog";
import CustomLoader from "app/components/common/CustomLoader";
import { getSchoolStaffPermissions } from "app/services/schoolService/access.service";

const AccessControl = ({ staffId }) => {
  const [selectedDeptId, setSelectedDeptId] = useState("");
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const medium = useSelector(selectedMediumSelector);
  const [showRoles, setShowRoles] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");
  const [accessData, setAccessData] = useState({});

  const {
    isFetching: getPermissionsFetching,
    isLoading: getPermissionsLoading,
  } = useQuery({
    queryKey: ["getSchoolStaffPermissions", staffId],
    queryFn: () =>
      getSchoolStaffPermissions({
        schoolDocId: schoolDocId,
        staffDocId: staffId,
        medium: medium,
      }),
    onSuccess: (data) => {
      setAccessData(data?.data);
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    },
    enabled: staffId ? true : false,
  });

  // Fetch departments on mount

  return (
    <div>
      {/* Dialog for Primary Role */}
      <PrimaryRoleDialog
        open={showRoles}
        handleCloseDialog={() => setShowRoles(false)}
        selectedStaffId={staffId}
        initialRole={selectedButton}
        handleRefresh={() => {
          getSchoolStaffPermissions({
            schoolDocId: schoolDocId,
            staffDocId: staffId,
          });
        }}
      />

      <CustomLoader
        show={getPermissionsLoading || getPermissionsFetching}
        message="Fetching Permissions"
      />

      <Helmet>
        <title>Access Control</title>
      </Helmet>

      <Container maxWidth="xl">
        {accessData?.primaryRole && (
          <>
            <Stack direction={"row"} gap={2}>
              <Box
                sx={{
                  mt: 2,
                  backgroundColor: THEME.white,
                  borderRadius: "5px",
                  display: "inline-block",
                  px: 1.5,
                  py: 1.2,
                  boxShadow: THEME.boxShadow1,
                }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "16px", opacity: 0.85 }}
                  >
                    Primary Role :
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      letterSpacing: "0.8px",
                    }}
                  >
                    {accessData?.primaryRole}
                  </Typography>
                  <PencilSquare
                    onClick={() => {
                      setSelectedButton(`${accessData?.primaryRole}`);
                      setShowRoles(true);
                    }}
                    size={16}
                    style={{ color: "blue", cursor: "pointer" }}
                  />
                </Stack>
              </Box>
              {/* <Link
                to={`/${appRoutes.staffProfile}/${staffId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2, px: 1.5, py: 1.5 }}
                >
                  View Profile
                </Button>
              </Link> */}
            </Stack>
            {Object.keys(accessData?.list || {}).map((moduleKey) => {
              const module = accessData?.list[moduleKey];

              return (
                <Row
                  className="mt-4 gy-4"
                  key={`${selectedDeptId}-${staffId}-${moduleKey}`}
                >
                  <h5>{moduleKey}</h5>
                  {Object.keys(module).map((screenKey) => {
                    const screens = module[screenKey];

                    return (
                      <Col sm="12" md="6" lg="4" key={module}>
                        <Accordion key={screenKey}>
                          <Accordion.Item eventKey={screenKey}>
                            <Accordion.Header>{screenKey}</Accordion.Header>
                            <Accordion.Body>
                              {screens?.length > 0 ? (
                                screens?.length > 1 ? (
                                  screens?.map((screen) => (
                                    <Accordion key={screen?.classDocId}>
                                      <Accordion.Item
                                        eventKey={screen?.classDocId}
                                        style={{ marginTop: "5px" }}
                                      >
                                        <Accordion.Header>
                                          {screen?.class_stream_section}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <AccessOperations
                                            screen={screen}
                                            userDocId={staffId}
                                          />
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  ))
                                ) : (
                                  <AccessOperations
                                    screen={screens[0]}
                                    userDocId={staffId}
                                  />
                                )
                              ) : null}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </>
        )}
      </Container>
    </div>
  );
};

export default AccessControl;
