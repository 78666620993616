import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import { Stack, Typography, Button, DialogContent, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "react-query";
import { changeRole, getPrimaryRolesForSchool } from "app/services/management";
import { toast } from "react-toastify";
const PrimaryRoleDialog = ({
  handleCloseDialog,
  open = false,
  selectedStaffId,
  handleRefresh,
  initialRole = "",
  normalUse = false,
  primaryRole, //this is only used if normalUse is true
  setPrimaryRole, //this is only used if normalUse is true
}) => {
  const [state, setSate] = useState({
    primaryRole: "",
    dialog: false,
    showRole: "",
  });
  const updatePrimaryRole = () => {
    let query = {
      staffDocId: selectedStaffId,
      requestedRole: state.primaryRole,
    };

    if (normalUse === true) {
      handleNormalUserRoleChange();
    } else {
      changeRoleMutate(query);
    }
  };

  const handleNormalUserRoleChange = () => {
    setPrimaryRole(state?.primaryRole);
    closeDialog();
    handleRefresh();
  };

  const closeDialog = () => {
    handleCloseDialog();
    setSate((prev) => ({
      ...prev,
      primaryRole: "",
      dialog: false,
      showRole: "",
    }));
  };

  const { data: getPrimaryRolesForSchoolData } = useQuery({
    queryKey: ["getPrimaryRolesForSchool"],
    queryFn: () => getPrimaryRolesForSchool(),
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    onSuccess: (data) => {
      if (normalUse) {
        setPrimaryRole(data?.data?.list[0]);
      }
    },
  });
  let DataList = getPrimaryRolesForSchoolData?.data?.list;
  const { mutate: changeRoleMutate, isLoading: changeRoleLoading } =
    useMutation(changeRole, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleRefresh();
        closeDialog();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  useEffect(() => {
    if (initialRole) {
      setSate((prev) => ({ ...prev, showRole: initialRole }));
    }
  }, [initialRole]);

  return (
    <CommonDialog
      open={open}
      onClose={closeDialog}
      title="Change Role"
      minWidth="600px"
    >
      <DialogContent>
        <Stack>
          <Typography
            sx={{
              fontSize: "15px",
              color: " rgba(12, 47, 73, 1)",
              fontWeight: "500",
            }}
          >
            Available Roles
          </Typography>
        </Stack>

        <Grid container spacing={2} sx={{ padding: "15px" }}>
          {DataList?.map((item, ind) => (
            <Grid item xs={4} key={item}>
              <Button
                variant="outlined"
                sx={{
                  // border: "1px solid lightgrey",
                  border: `1px solid ${
                    state.showRole === String(item).toUpperCase()
                      ? "black"
                      : "lightgrey"
                  }`,
                  borderRadius: "10px",
                  padding: "10px",
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => {
                  setSate((prev) => ({
                    ...prev,
                    showRole: String(item).toUpperCase(),
                    primaryRole: item,
                  }));
                  if (normalUse) {
                    setPrimaryRole(item);
                  }
                }}
              >
                {item}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Stack direction="row" justifyContent="center" sx={{ py: 1 }}>
          <LoadingButton
            loading={changeRoleLoading}
            size="large"
            variant="contained"
            color="secondary"
            onClick={updatePrimaryRole}
            disabled={!Boolean(state.primaryRole)}
          >
            Submit
          </LoadingButton>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
};

export default PrimaryRoleDialog;
